import { useMemo } from 'react';

import { Currencies } from '../../../../../../types';
import { InvoiceItemsViewInvoice } from '../../InvoiceItemsView.types';
import {
  FetchInvoicesCacheKeys,
  InvoiceNanoID,
  InvoiceStatuses
} from '../../../../invoicesTypes';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import { InvoiceItemsViewContextType } from '../useInvoiceItemsViewContext';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useInvoiceItemsViewViewModes } from '../useInvoiceItemsViewViewModes';
import { useInvoiceItemsViewFields } from '../useInvoiceItemsViewFields';
import { useInvoiceItemsViewItems } from '../useInvoiceItemsViewItems';
import { useInvoiceItemsViewComputedFields } from '../useInvoiceItemsViewComputedFields';
import { useInvoiceItemsViewHandlers } from '../useInvoiceItemsViewHandlers';
import { useInvoiceItemsViewPreparedItems } from '../useInvoiceItemsViewPreparedItems';
import { useInvoiceItemsViewCheckable } from '../useInvoiceItemsViewCheckable';

import { getCurrencyPrefix } from '../../../../../currencyRates/utils/getCurrencyPrefix';

interface InvoiceItemsViewOptions {
  invoice?: InvoiceItemsViewInvoice;
  invoiceNanoId: InvoiceNanoID;
  thirdPersonView?: boolean;
  self?: boolean;
  cacheKeys?: FetchInvoicesCacheKeys;
  readonly?: boolean;
  customFieldsCorrectStatus?: boolean;
}

function useInvoiceItemsView({
  invoice,
  invoiceNanoId,
  thirdPersonView,
  self,
  cacheKeys: invoiceCacheKeys,
  readonly,
  customFieldsCorrectStatus
}: InvoiceItemsViewOptions) {
  const currentUser = useCurrentUser();

  const { viewModeOptions, groupByOptions } = useInvoiceItemsViewViewModes();

  const {
    control,
    // errors,
    watchFields,
    registerFields
  } = useInvoiceItemsViewFields({
    defaultCurrency: invoice?.selectedCurrency,
    defaultViewMode:
      invoice?.viewSettings?.viewMode || ProformaInvoiceFormViewMode.INVOICES,
    defaultGroupBy:
      invoice?.viewSettings?.groupBy || ProformaInvoiceFormGroupByMode.PROJECT,
    defaultEnableGrouping: invoice?.viewSettings?.enableGrouping ?? true,
    groupByOptions
  });

  const currencyPrefix = getCurrencyPrefix(invoice?.selectedCurrency);

  const {
    cacheKeys: groupedItemsCacheKeys,
    groupedItemsFetched,
    groupedItems,
    groupedItemsLoading,
    groupedItemsError
  } = useInvoiceItemsViewItems({
    invoiceNanoId: invoice?.nanoId,
    currencyRateId: invoice?.currencyRateId,
    currency: invoice?.selectedCurrency,
    viewMode: watchFields.watchViewMode,
    groupBy: watchFields.watchGroupBy,
    enableGrouping: watchFields.watchEnableGrouping
  });

  const cacheKeys = useMemo(
    () => [...invoiceCacheKeys, ...groupedItemsCacheKeys],
    [invoiceCacheKeys, groupedItemsCacheKeys]
  );

  const { preparedItems, flatItems } = useInvoiceItemsViewPreparedItems({
    items: groupedItems
  });

  const {
    checkedHash,
    checkedAll,
    checkedItems,
    checkedPartial,
    handleSetCheckedIds,
    handleCheckAll,
    handleUncheckAll
  } = useInvoiceItemsViewCheckable({
    items: flatItems
  });

  const {
    invoiceStatus,
    payments,
    prepayment,
    prepaymentAmount,
    amountDue,
    invoiceTotal,
    checkedItemsTotal,
    scStatuses
  } = useInvoiceItemsViewComputedFields({
    invoice,
    checkedItems,
    items: flatItems
  });

  const {
    handleChangeCurrency,
    updateViewSettingsInvoiceLoading,
    updateViewSettingsInvoiceError,
    changeCurrencyLoading,
    changeCurrencyError
  } = useInvoiceItemsViewHandlers({
    invoice,
    cacheKeys,
    viewMode: watchFields.watchViewMode,
    groupBy: watchFields.watchGroupBy,
    enableGrouping: watchFields.watchEnableGrouping
  });

  const context: InvoiceItemsViewContextType = {
    invoice,
    invoiceNanoId,
    control,
    // errors,
    items: preparedItems,
    groupedItemsFetched,
    groupedItemsLoading,
    groupedItemsError,
    updateViewSettingsInvoiceLoading,
    updateViewSettingsInvoiceError,
    changeCurrencyLoading,
    changeCurrencyError,
    checkedAll,
    checkedPartial,
    checkedHash,
    checkedItems,
    handleCheckAll,
    handleUncheckAll,
    handleSetCheckedIds,
    cacheKeys,
    viewModeOptions,
    groupByOptions,
    watchFields,
    registerFields,
    currentUser,
    currencyPrefix,
    invoiceStatus,
    payments,
    prepayment,
    prepaymentAmount,
    amountDue,
    invoiceTotal,
    checkedItemsTotal,
    thirdPersonView,
    self,
    handleChangeCurrency,
    isDraft: invoice?.clientStatus === InvoiceStatuses.DRAFT,
    readonly,
    customFieldsCorrectStatus,
    selectedCurrency: invoice?.selectedCurrency || Currencies.USD,
    scStatuses
  };

  return context;
}

export default useInvoiceItemsView;
