import { Control } from 'react-hook-form';
import cl from 'classnames';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import {
  CreateInvoiceCustomFieldFormData,
  CreateInvoiceCustomFieldFormTabs
} from './CreateInvoiceCustomFieldForm.types';
import {
  CustomFieldFields,
  CustomFieldID,
  CustomFieldLocations,
  CustomFieldOptions
} from '../../../../customFields/customFieldsTypes';
import { CompanyID } from '../../../../companies/companiesTypes';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';
import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { CustomFieldValuesMultiSelectField } from '../../../../customFieldValues/components/helpers/CustomFieldValuesMultiSelectField';
import { CustomFieldsMultiSelectField } from '../../../../customFields/helpers/CustomFieldsMultiSelectField';
import { CustomFieldLocationsSelectField } from '../../../../customFields/helpers/CustomFieldLocationsSelectField';

import {
  customFieldsKeys,
  formsFields,
  stringsKeys,
  words
} from '../../../../../locales/keys';

interface CreateInvoiceCustomFieldFormProps {
  form: string;
  isLoading: IsLoading;
  control: Control<CreateInvoiceCustomFieldFormData>;
  registerBody: RegisterFormFieldType<HTMLInputElement>;
  registerLabel: RegisterFormFieldType<HTMLInputElement>;
  registerMultiline: RegisterFormFieldType<HTMLInputElement>;
  registerSaveInCompany: RegisterFormFieldType<HTMLInputElement>;
  labelValidationError: ErrorMessage;
  locationValidationError: ErrorMessage;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  activeTab: CreateInvoiceCustomFieldFormTabs;
  setActiveTab: (tab: CreateInvoiceCustomFieldFormTabs) => void;
  companyId: CompanyID;
  customFieldsLocation: CustomFieldLocations;
  watchCustomFieldId: CustomFieldID;
  watchOptions: CustomFieldOptions;
  watchSaveInCompany: boolean;
}

function CreateInvoiceCustomFieldForm({
  form,
  isLoading,
  control,
  registerBody,
  registerLabel,
  registerMultiline,
  registerSaveInCompany,
  labelValidationError,
  locationValidationError,
  withoutLabel,
  withoutLocation,
  activeTab,
  setActiveTab,
  companyId,
  customFieldsLocation,
  watchCustomFieldId,
  watchOptions,
  watchSaveInCompany
}: CreateInvoiceCustomFieldFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="flex border-b border-gray-200 dark:border-gray-700">
          <div className="-mb-px flex flex-auto">
            <PureButtonHelper
              className={cl(
                'whitespace-nowrap px-2 py-1.5 sm:p-3 3xl:p-4 font-medium text-xs 3xl:text-sm flex items-center gap-1 border-b-2',
                activeTab === CreateInvoiceCustomFieldFormTabs.EXISTING_FIELD
                  ? 'border-blue-500'
                  : 'border-transparent'
              )}
              onClick={() =>
                setActiveTab(CreateInvoiceCustomFieldFormTabs.EXISTING_FIELD)
              }
              i18nText={words.existingField}
            />
            <PureButtonHelper
              className={cl(
                'whitespace-nowrap px-2 py-1.5 sm:p-3 3xl:p-4 font-medium text-xs 3xl:text-sm flex items-center gap-1 border-b-2',
                activeTab === CreateInvoiceCustomFieldFormTabs.NEW_FIELD
                  ? 'border-blue-500'
                  : 'border-transparent'
              )}
              onClick={() =>
                setActiveTab(CreateInvoiceCustomFieldFormTabs.NEW_FIELD)
              }
              i18nText={words.newField}
            />
          </div>
        </div>
      </div>

      {/* New field*/}
      {activeTab === CreateInvoiceCustomFieldFormTabs.NEW_FIELD && (
        <div className="p-4 space-y-4">
          {!withoutLabel && (
            <InputField
              disabled={isLoading}
              error={labelValidationError}
              i18nLabel={formsFields.label}
              inputWrapperClassName="relative mt-1"
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
              name={registerLabel.name}
              onChange={registerLabel.onChange}
              ref={registerLabel.ref}
            />
          )}

          <div>
            <TextareaAutosizeField<CreateInvoiceCustomFieldFormData>
              i18nLabel={formsFields.body}
              control={control}
              disabled={isLoading}
              className="basic-input resize-none"
              inputWrapperClassName="relative"
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              name={registerBody.name as keyof CreateInvoiceCustomFieldFormData}
              maxRows={5}
            />
          </div>

          <CheckBoxField
            id={registerMultiline.name}
            i18nLabel={words.multiline}
            name={registerMultiline.name}
            onChange={registerMultiline.onChange}
            ref={registerMultiline.ref}
            checked={!!watchOptions?.multiline}
          />

          {!withoutLocation && (
            <CustomFieldLocationsSelectField<CreateInvoiceCustomFieldFormData>
              control={control}
              name={CustomFieldFields.LOCATION}
              i18nLabel={words.location}
              error={locationValidationError}
              i18nPlaceholder={customFieldsKeys.selectLocation}
            />
          )}

          <CheckBoxField
            id={registerSaveInCompany.name}
            i18nLabel={stringsKeys.saveFieldInCompanyProfile}
            name={registerSaveInCompany.name}
            onChange={registerSaveInCompany.onChange}
            ref={registerSaveInCompany.ref}
            checked={watchSaveInCompany}
          />
        </div>
      )}

      {activeTab === CreateInvoiceCustomFieldFormTabs.EXISTING_FIELD && (
        <div className="p-4 space-y-4">
          <div>
            <CustomFieldsMultiSelectField<CreateInvoiceCustomFieldFormData>
              inputWrapperClassName="mt-1"
              name={CustomFieldFields.CUSTOM_FIELD}
              control={control}
              i18nLabel={words.field}
              companyId={companyId}
              customFieldsLocation={customFieldsLocation}
              autoSelectSingleOption
            />
          </div>

          <div>
            <CustomFieldValuesMultiSelectField<CreateInvoiceCustomFieldFormData>
              inputWrapperClassName="mt-1"
              name={CustomFieldFields.CUSTOM_FIELD_VALUE}
              disabled={!watchCustomFieldId}
              control={control}
              i18nLabel={formsFields.text}
              customFieldId={watchCustomFieldId}
              autoSelectSingleOption
            />
          </div>
        </div>
      )}
    </Form>
  );
}

export default CreateInvoiceCustomFieldForm;
