import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';

import { InvoiceViewItemsTableCheckedItemsType } from '../useInvoiceItemsViewContext';
import {
  InvoiceAmount,
  InvoiceClientStatus,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceSelectedCurrency,
  InvoiceStatus,
  InvoiceStatuses,
  InvoiceTempTotalConverted,
  InvoiceTotal,
  InvoiceTotalConverted
} from '../../../../invoicesTypes';
import { amountMultiply } from '../../../../../../utils/amountMultiply';
import { Currencies } from '../../../../../../types';
import { ItemPrice } from '../../../../../items/itemsTypes';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

interface InvoiceItemsViewComputedFieldsProps {
  invoice: {
    amount: InvoiceAmount;
    invoiceTotal: InvoiceTotal;
    invoiceTotalConverted: InvoiceTotalConverted;
    invoiceTempTotalConverted: InvoiceTempTotalConverted;
    prepayment: InvoicePrepayment;
    prepaymentAmount: InvoicePrepaymentAmount;
    clientStatus: InvoiceClientStatus;
    status: InvoiceStatus;
    selectedCurrency: InvoiceSelectedCurrency;
    secondaryInvoice?: {
      amount: InvoiceAmount;
      clientStatus: InvoiceClientStatus;
      status: InvoiceStatus;
    };
    tempItems?: {
      price: ItemPrice;
    }[];
  };
  checkedItems?: InvoiceViewItemsTableCheckedItemsType;
  items?: InvoiceViewItemsTableCheckedItemsType;
}

function useInvoiceItemsViewComputedFields({
  invoice,
  checkedItems = [],
  items = []
}: InvoiceItemsViewComputedFieldsProps) {
  const checkedItemsTotal = useMemo(
    () => sumBy(checkedItems, 'convertedPrice'),
    [checkedItems]
  );

  const primaryInvoiceStatus = invoice?.clientStatus || invoice?.status;

  const secondaryInvoiceStatus =
    invoice?.secondaryInvoice?.clientStatus ||
    invoice?.secondaryInvoice?.status;

  const invoiceStatus =
    secondaryInvoiceStatus === InvoiceStatuses.PAID
      ? secondaryInvoiceStatus
      : primaryInvoiceStatus;

  const prepayment = invoice?.prepayment || 0;

  const prepaymentAmountUsd = invoice?.prepaymentAmount || 0;

  const paymentsUsd =
    (invoice?.status === InvoiceStatuses.PAID && invoice?.amount) +
    (invoice?.secondaryInvoice?.status === InvoiceStatuses.PAID &&
      invoice?.secondaryInvoice?.amount);

  const invoiceTotalTempUsd = useMemo(
    () => sumBy(invoice?.tempItems, (tempItem) => tempItem.price),
    [invoice?.tempItems]
  );

  const invoiceTotalUsd =
    invoice?.status === InvoiceStatuses.DRAFT
      ? invoiceTotalTempUsd
      : invoice?.invoiceTotal;

  const invoiceTotalCurrency =
    invoice?.status === InvoiceStatuses.DRAFT
      ? invoice?.invoiceTempTotalConverted
      : invoice?.invoiceTotalConverted;

  const amountDue =
    invoiceStatus === InvoiceStatuses.PREPAID
      ? invoice?.secondaryInvoice?.amount
      : invoice?.amount;

  const amountDueUsd =
    invoice?.status === InvoiceStatuses.DRAFT ? invoiceTotalUsd : amountDue;

  const prepaymentAmountCurrency = useMemo(
    () =>
      amountMultiply(
        invoiceTotalCurrency,
        prepaymentAmountUsd / (invoiceTotalUsd || 1)
      ),
    [invoiceTotalCurrency, invoiceTotalUsd, prepaymentAmountUsd]
  );

  const amountDueCurrency = useMemo(
    () =>
      amountMultiply(
        invoiceTotalCurrency,
        amountDueUsd / (invoiceTotalUsd || 1)
      ),
    [amountDueUsd, invoiceTotalCurrency, invoiceTotalUsd]
  );

  const paymentsCurrency = useMemo(
    () =>
      amountMultiply(
        invoiceTotalCurrency,
        paymentsUsd / (invoiceTotalUsd || 1)
      ),
    [paymentsUsd, invoiceTotalCurrency, invoiceTotalUsd]
  );

  const isUsd = invoice?.selectedCurrency === Currencies.USD;

  const scStatuses = useMemo(
    () => uniq(map(items, (item) => item.smartContract.status)),
    [items]
  );

  return {
    invoiceStatus,
    payments: isUsd ? paymentsUsd : paymentsCurrency,
    prepayment,
    prepaymentAmount: isUsd ? prepaymentAmountUsd : prepaymentAmountCurrency,
    amountDue: isUsd ? amountDueUsd : amountDueCurrency,
    invoiceTotal: isUsd ? invoiceTotalUsd : invoiceTotalCurrency,
    checkedItemsTotal,
    scStatuses
  };
}

export default useInvoiceItemsViewComputedFields;
