import React, { useCallback, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import find from 'lodash/find';

import { CustomFieldValuesSelectFieldProps } from './CustomFieldValuesMultiSelectField.types';
import { MultiSelectDataType } from '../../../../../helpers/MultiSelect/types';

import {
  FETCH_CUSTOM_FIELD_VALUES_QUERY,
  FetchCustomFieldValuesQueryResponse
} from '../../../queries/fetchCustomFieldValues.query';

import { usePaginatedCustomFieldValues } from '../../../hooks/usePaginatedCustomFieldValues';
import { usePreviousValue } from '../../../../../common/hooks/usePreviousValue';

import {
  MultiSelectField,
  MultiSelectFieldChangeCallbackType
} from '../../../../../helpers/FormFields/MultiSelectField';

import { CustomFieldValueCache } from '../../../CustomFieldValueCache';

function CustomFieldValuesMultiSelectField<FormDataType>({
  autoFocus,
  classNamePrefix,
  control,
  error,
  disabled,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  name,
  onBlur,
  onChange,
  onMenuClose,
  onMenuOpen,
  openMenuOnFocus,
  multi,
  customFieldId,
  autoSelectSingleOption
}: CustomFieldValuesSelectFieldProps<FormDataType>) {
  const {
    field: { onChange: setValue, value }
  } = useController({
    name,
    control
  });

  const {
    customFieldValues,
    customFieldValuesLoading,
    customFieldValuesError,
    filterCustomFieldValues
  } = usePaginatedCustomFieldValues<FetchCustomFieldValuesQueryResponse>({
    cacheKey: CustomFieldValueCache.customFieldCustomFieldValues(customFieldId),
    query: FETCH_CUSTOM_FIELD_VALUES_QUERY,
    initialFilters: {
      customFieldId: { eq: customFieldId }
    }
  });

  const prevCustomFieldId = usePreviousValue(customFieldId);

  useEffect(() => {
    if (prevCustomFieldId !== customFieldId && customFieldId) {
      filterCustomFieldValues({
        customFieldId: { eq: customFieldId }
      });
    }
  }, [customFieldId, filterCustomFieldValues, prevCustomFieldId]);

  const options = useMemo<MultiSelectDataType[]>(
    () =>
      map(customFieldValues, (field) => ({
        value: field.id,
        label: field.text
      })),
    [customFieldValues]
  );

  const prevOptions = usePreviousValue(options);

  const handleOnChange = useCallback<MultiSelectFieldChangeCallbackType>(
    (newValue) => {
      onChange?.(
        isArray(newValue)
          ? map(newValue, (value) =>
              find(customFieldValues, (tag) => tag.id === value.value)
            )
          : find(customFieldValues, (tag) => tag.id === newValue.value)
      );
    },
    [customFieldValues, onChange]
  );

  useEffect(() => {
    if (prevOptions !== options) {
      setValue(null);
    }
  }, [options, prevOptions, setValue]);

  useEffect(() => {
    if (autoSelectSingleOption && options.length === 1 && !value) {
      setValue(options[0]);
    }
  }, [autoSelectSingleOption, options, prevOptions, setValue, value]);

  return (
    <MultiSelectField<FormDataType>
      autoFocus={autoFocus}
      classNamePrefix={
        classNamePrefix || (customFieldValuesError ? 'av_error' : 'av')
      }
      control={control}
      data={options}
      disabled={disabled || customFieldValuesLoading}
      emptyValue={undefined}
      error={error || customFieldValuesError}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isSearchable
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      menuPosition="fixed"
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      multi={multi}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      openMenuOnFocus={openMenuOnFocus}
      optionsLoading={customFieldValuesLoading}
    />
  );
}

export default CustomFieldValuesMultiSelectField;
